<template>
	<v-tab v-on="$listeners">
		{{ $t('team_manager.tabs_title.matrix_theme_accountant') }}
		<v-icon>security</v-icon>
	</v-tab>
</template>

<script>
export default {
	name: 'ThemeAssignmentTab'
}
</script>
